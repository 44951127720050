// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-de-nous-js": () => import("./../../../src/pages/a-propos-de-nous.js" /* webpackChunkName: "component---src-pages-a-propos-de-nous-js" */),
  "component---src-pages-carrieres-index-js": () => import("./../../../src/pages/carrieres/index.js" /* webpackChunkName: "component---src-pages-carrieres-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-old-js": () => import("./../../../src/pages/contact-old.js" /* webpackChunkName: "component---src-pages-contact-old-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lausanne-js": () => import("./../../../src/pages/lausanne.js" /* webpackChunkName: "component---src-pages-lausanne-js" */),
  "component---src-pages-ligne-de-crise-js": () => import("./../../../src/pages/ligne-de-crise.js" /* webpackChunkName: "component---src-pages-ligne-de-crise-js" */),
  "component---src-pages-neuchatel-js": () => import("./../../../src/pages/neuchatel.js" /* webpackChunkName: "component---src-pages-neuchatel-js" */),
  "component---src-pages-politique-de-confidentialite-js": () => import("./../../../src/pages/politique-de-confidentialite.js" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-js" */),
  "component---src-pages-prestations-js": () => import("./../../../src/pages/prestations.js" /* webpackChunkName: "component---src-pages-prestations-js" */),
  "component---src-pages-reservation-js": () => import("./../../../src/pages/reservation.js" /* webpackChunkName: "component---src-pages-reservation-js" */)
}

